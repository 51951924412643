<template>
  <div class="d-flex flex-column">
    <v-row class="tabs-toolbar align-self-end justify-end align-center mx-4">
      <!-- Sort -->
      <v-chip
        class="mx-2"
        color="white"
        text-color="secundary"
        @click="sortDesc = !sortDesc"
      >
        <v-icon class="mr-1" v-text="'sort_by_alpha'" />
        {{ $t('common.alphabetical') }}
      </v-chip>
      <!-- Search -->
      <v-text-field
        v-model="search"
        class="flex-grow-0"
        :label="$t('common.searchByName')"
        prepend-inner-icon="search"
        tag="span"
        outlined
        dense
        hide-details
      />
    </v-row>
    <!-- Tabs -->
    <v-tabs v-model="tab" color="secundary">
      <v-tabs-slider color="primary" />
      <v-tab v-for="{ id, name } in tabs" :key="id" class="text-capitalize" v-text="name" />
    </v-tabs>
    <v-divider />
    <!-- Content -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ id, content } in tabs" :key="id">
        <v-data-iterator
          v-if="content.length"
          class="px-6 py-4"
          :items="content"
          :search="search"
          :sort-by="sortBy.toLowerCase()"
          :sort-desc="sortDesc"
          hide-default-footer
          disable-pagination
        >
          <!-- Cards -->
          <template #default="props">
            <v-row>
              <v-col v-for="item in props.items" :key="item.id" cols="12" sm="6" md="4" lg="3">
                <v-card class="mx-auto" max-width="344" :to="{ name: 'organization', params: { organizationId: item.id }}">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="headline mb-1" v-text="item.name" />
                    </v-list-item-content>
                  </v-list-item>

                  <v-card-actions class="px-4 pb-4 ma-0">
                    <v-spacer />
                    <v-btn
                      icon color="primary"
                      @click.prevent="item.isFavorite
                        ? removeFavoriteOrganization({ id: userId, organizationId: item.id})
                        : addFavoriteOrganization({ id: userId, organizationId: item.id})"
                      @mousedown.stop
                    >
                      <v-icon v-text="item.isFavorite ? 'star' : 'star_border'" />
                    </v-btn>
                    <v-btn icon color="primary" :to="{ name: 'organization-staff-list', params: { organizationId: item.id } }" @mousedown.stop @click.prevent>
                      <v-icon>person_outline</v-icon>
                    </v-btn>
                    <v-menu bottom left>
                      <template #activator="{ on, attrs }">
                        <v-btn color="primary" icon v-bind="attrs" v-on="on" @mousedown.stop @click.prevent>
                          <v-icon>more_vert</v-icon>
                        </v-btn>
                      </template>
                      <!-- Menu list -->
                      <v-list>
                        <v-list-item @click="createClone({ organizationId: item.id })">
                          <v-list-item-title>{{ $t('common.duplicate') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="$confirm($t('organization.confirmDelete')).then( (res) => res ? remove({ organizationId: item.id }) : '' ) ">
                          <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'OrganizationList',
  data() {
    return {
      tab: null,
      search: '',
      sortBy: 'name',
      sortDesc: false,
    }
  },
  computed: {
    organizationList() { return this.$store.getters['superadmin/organizations'] },
    userId() { return this.$store.state.user.dbData.id },
    tabs({ organizationList }) {
      return [
        {
          id: 'all',
          name: this.$t('filters.all'),
          content: organizationList,
        },
        {
          id: 'favorites',
          name: this.$t('filters.favorites'),
          content: organizationList.filter(organization => organization.isFavorite),

        },
      ]
    },
  },
  methods: {
    ...mapActions('organization', ['createClone']),
    ...mapActions('user', ['addFavoriteOrganization', 'removeFavoriteOrganization']),
    remove({ organizationId }) {
      this.runAsync(() => this.$store.dispatch('organization/delete', { organizationId }))
    },
  },
}
</script>
